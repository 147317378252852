import React from 'react';
import { useLocation } from 'react-router-dom';

export const ModalContext = React.createContext();

export const ModalStorage = ({ children }) => {
  //modal abre e fecha
  const [modal, setModal] = React.useState(false);
  //recebe provisoriamente os dados do item manipulado dentro do modal
  const [dataModal, setDataModal] = React.useState([]);
  //tipo do modal
  const [typeModal, setTypeModal] = React.useState();
  //numero do item que abre no modal
  const [nItem, setNitem] = React.useState();
  //pega atual route
  const location = useLocation();

  //itens exercicios by fetch - PRINCIPAL CONST EM EXERCÍCIOS
  const [itensExercicio, setItensExercicio] = React.useState([]);

  React.useEffect(() => {
    console.log('mudou');
    setItensExercicio([]);
  }, [location]);

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        itensExercicio,
        setItensExercicio,
        dataModal,
        setDataModal,
        typeModal,
        setTypeModal,
        setNitem,
        nItem,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
