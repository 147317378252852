import React from 'react';
import styles from './StopWatch.module.css';

const StopWatch = ({
  isActive,
  setIsActive,
  isPaused,
  setIsPaused,
  time,
  setTime,
}) => {
  React.useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
  };

  return (
    <div className={styles.timer}>
      <span className={`${styles.cron}`}></span>
      <p>
        <span className="digits">
          {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits">
          {('0' + Math.floor((time / 1000) % 60)).slice(-2)}
        </span>
        {/*         <span className="digits mili-sec">
          {('0' + ((time / 10) % 100)).slice(-2)}
        </span> */}
      </p>
      {isPaused && (
        <span
          className={`${styles.icon} ${styles.play}`}
          onClick={handleStart}
        ></span>
      )}
      {!isPaused && (
        <span
          className={`${styles.icon} ${styles.pause}`}
          onClick={handlePauseResume}
        ></span>
      )}
    </div>
  );
};

export default StopWatch;
