export const API_URL = 'https://www.toco3d.com/api_npt/json'; //produção
//export const API_URL = 'http://testes.toco3d.com/api/json'; //testes

/*---GET TOKEN---*/
export function TOKEN_POST(body) {
  return {
    url: API_URL + '/jwt-auth/v1/token',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*---TOKEN VALIDATE--*/
export function TOKEN_VALIDATE_POST(token) {
  return {
    url: API_URL + '/jwt-auth/v1/token/validate',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer' + token,
      },
    },
  };
}

/*----USER GET----*/
export function USER_GET(token) {
  return {
    url: API_URL + `/api/user`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer' + token,
      },
    },
  };
}

/*---USERS GET----*/
export function USERS_GET({ token, page, nItens, q }) {
  //console.log(q);
  return {
    url: API_URL + `/api/users?_page=${page}&_limit=${nItens}&q=${q}`,
    options: {
      method: 'GET',
      //cache: 'force-cache',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---USER DELETABLE----*/
export function USER_DELETE({ exID, token }) {
  return {
    url: API_URL + `/api/user/${exID}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*----USER GET BY ID----*/
export function USER_GET_BY_ID({ token, theID }) {
  return {
    url: API_URL + `/api/user/${theID}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer' + token,
      },
    },
  };
}

/*-----USER POST ----*/
export function USER_POST({ token, body }) {
  return {
    url: API_URL + `/api/user`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----USER PUT ----*/
export function USER_PUT({ token, body, alunoID }) {
  return {
    url: API_URL + `/api/user/${alunoID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----USER TREINO PUT ----*/
export function USER_TREINO_PUT({ token, body, alunoID }) {
  return {
    url: API_URL + `/api/user_treino/${alunoID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----CALENDARIO PUT ----*/
export function USER_CALENDARIO_PUT({ token, body, alunoID }) {
  return {
    url: API_URL + `/api/user_calendario/${alunoID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----USER_IMAGE_PUT PUT ----*/
export function USER_IMAGE_PUT({ token, photo }) {
  return {
    url: API_URL + `/api/user_image`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: photo,
    },
  };
}

/*-----MANAGER AREA PUT ----*/
export function MANAGER_PUT({ token, body }) {
  return {
    url: API_URL + `/api/manager_area`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*----INSTRUTOR CREF GET----*/
export function INSTRUTOR_CREF_GET({ token, theID }) {
  return {
    url: API_URL + `/api/instrutor_cref/${theID}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer' + token,
      },
    },
  };
}

/*---EXERCICIOS GET----*/
export function EXERCICIOS_GET({ token, page, term, nItens, rascunhos, q }) {
  //console.log(q);
  return {
    url:
      API_URL +
      `/api/exercicios?_page=${page}&_limit=${nItens}&term_id=${term}&rascunhos=${rascunhos}&q=${q}`,
    options: {
      method: 'GET',
      //cache: 'force-cache',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---EXERCICIO GET----*/
export function EXERCICIO_GET({ exID, token }) {
  return {
    url: API_URL + `/api/exercicio/${exID}`,
    options: {
      //cache: 'force-cache',
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---EXERCICIO EDIT GET----*/
export function EXERCICIO_EDIT_GET({ exID, token }) {
  return {
    url: API_URL + `/api/exercicio_edit/${exID}`,
    options: {
      //cache: 'force-cache',
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*-----EXERCICIO POST ----*/
export function EXERCICIO_POST({ token, body }) {
  return {
    url: API_URL + `/api/exercicio`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----EXERCICIO PUT----*/
export function EXERCICIO_PUT({ exID, token, body }) {
  return {
    url: API_URL + `/api/exercicio/${exID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*---EXERCICIO DELETABLE----*/
export function EXERCICIO_DELETE({ exID, token }) {
  return {
    url: API_URL + `/api/exercicio/${exID}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---TREINO GET----*/
export function TREINO_GET({ exID, token, userID }) {
  return {
    url: API_URL + `/api/treino/${userID}/${exID}`,
    options: {
      //cache: 'force-cache',
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---TREINOS ALUNO GET----*/
export function TREINOS_ALUNO_GET({ token, ids }) {
  return {
    url: API_URL + `/api/treinos_aluno?ids=${ids}`,
    options: {
      //cache: 'force-cache',
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---TREINO DELETABLE----*/
export function TREINO_DELETE({ exID, token }) {
  return {
    url: API_URL + `/api/treino/${exID}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*-----TREINO POST ----*/
export function TREINO_POST({ token, body }) {
  return {
    url: API_URL + `/api/treino`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----GRUPO POST ----*/
export function GRUPO_POST({ token, body }) {
  return {
    url: API_URL + `/api/grupo_exercicios`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----GRUPO PUT----*/
export function GRUPO_PUT({ token, body, exID }) {
  return {
    url: API_URL + `/api/grupo_exercicios/${exID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*---GRUPOS GET----*/
export function GRUPOS_GET({ token, page, nItens, q }) {
  //console.log(rascunhos);
  return {
    url:
      API_URL + `/api/grupos_exercicios?_page=${page}&_limit=${nItens}&q=${q}`,
    options: {
      method: 'GET',
      //cache: 'force-cache',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---GRUPO GET----*/
export function GRUPO_GET({ grupoID, userID, token }) {
  //console.log(rascunhos);
  return {
    url: API_URL + `/api/grupo_exercicios/${userID}/${grupoID}`,
    options: {
      method: 'GET',
      //cache: 'force-cache',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---GRUPO DELETE----*/
export function GRUPO_DELETE({ exID, token }) {
  //console.log(rascunhos);
  return {
    url: API_URL + `/api/grupo_exercicios/${exID}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*----TERMS GET----*/
export function TERMS_GET({ token, slug }) {
  return {
    url: API_URL + `/api/exercicios/taxonomies/${slug}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*---TREINOS GET----*/
export function TREINOS_GET({ token, page, nItens, q }) {
  //console.log(rascunhos);
  return {
    url: API_URL + `/api/treinos?_page=${page}&_limit=${nItens}&q=${q}`,
    options: {
      method: 'GET',
      //cache: 'force-cache',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

/*-----TREINO PUT ----*/
export function TREINO_PUT({ token, body, exID, userID }) {
  return {
    url: API_URL + `/api/treino/${exID}?user_id=${userID}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

/*-----DUPLICATE POST ----*/
export function DUPLICATE_POST({ token, exID, userID }) {
  return {
    url: API_URL + `/api/duplicate_post?id=${exID}&author=${userID}`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
    },
  };
}

/*-----SEND EMAIL ----*/
export function SEND_MAIL({ token, type, alunoID }) {
  return {
    url: API_URL + `/api/send_email?id=${alunoID}&type=${type}`,
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
      },
    },
  };
}
