import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './RightArea.module.css';
import UserContext from '../Context/UserContext';
import useFetch from '../Hooks/useFetch';
import { USER_GET_BY_ID } from '../../api';

const RightArea = ({ page }) => {
  const token = window.localStorage.getItem('token');
  const { userType, userLogout, userID } = React.useContext(UserContext);
  const { data, request } = useFetch();
  const [modal, setModal] = React.useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    async function fetchUser() {
      const theID = userID;
      const { url, options } = USER_GET_BY_ID({ token, theID });
      const { response, json } = await request(url, options);
      //console.log(json);
    }
    fetchUser();
  }, []);

  if (page === 'aluno')
    return (
      <div className={styles.rightArea}>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.icon_msg}></div>
              {data && (
                <p className={styles.msg}>{data.treino_atual.mensagem}</p>
              )}
            </div>
            <div className={styles.close} onClick={() => setModal(false)}></div>
          </div>
        )}
        <div
          className={styles.icon + ' ' + styles.home}
          onClick={() => {
            document.location.reload(true);
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.message}
          onClick={() => {
            setModal(true);
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.config}
          onClick={() => {
            navigate('/editar-usuario');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.logout}
          onClick={() => {
            userLogout();
          }}
        ></div>
      </div>
    );
  //-------------------------------------------------------------------------------//
  else
    return (
      <div className={styles.rightArea}>
        <div
          className={styles.icon + ' ' + styles.home}
          onClick={() => {
            navigate('/');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.config}
          onClick={() => {
            navigate('/editar-usuario');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.logout}
          onClick={() => {
            userLogout();
          }}
        ></div>
      </div>
    );
};

export default RightArea;
