import React from 'react';
import { INSTRUTOR_CREF_GET } from '../../../api';
import Header from '../../Header/Header';
import useFetch from '../../Hooks/useFetch';
import Video from '../ListarExercicios/Video';
import styles from './AlunoTreino.module.css';
import Weeks from './Weeks';

const AlunoTreino = ({ alunoData }) => {
  //console.log(alunoData);
  //console.log(dates['7-8-2022'] !== undefined);
  // console.log(dates);
  const { request } = useFetch();
  const token = window.localStorage.getItem('token');

  const [calendario, setCalendario] = React.useState(alunoData.calendario);
  const [treinoWeek, setTreinoWeek] = React.useState(alunoData.treino_atual);
  const [currentDay, setCurrentDay] = React.useState('');
  const [instrutorData, setInstrutorData] = React.useState();
  const [instrutorType, setInstrutorType] = React.useState();
  const date = new Date();
  const [today, setToday] = React.useState(
    ('0' + date.getDate()).slice(-2) +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear(),
  );

  //Função pega a data inicial e final da semana e gera as datas intermediarias
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date).toLocaleDateString());
      date.setDate(date.getDate() + 1);
    }

    //troca / por -
    for (let i = 0; i < dates.length; i++) {
      dates[i] = dates[i].replace(/\//g, '-');
    }
    return dates;
  }

  //Gera a data inicial e final da semana e envia para funcao acima
  const curr = new Date();
  //const first = curr.getDate() - curr.getDay() + 1; // Start from Monday
  const first = curr.getDate() - curr.getDay(); // Start from Monday
  const firstDate = new Date(curr.setDate(first));
  const lastDate = new Date(curr.setDate(firstDate.getDate() + 6));
  const currentWeek = getDatesInRange(firstDate, lastDate);
  // console.log(currentWeek);

  React.useEffect(() => {
    const theID = alunoData.author;
    async function fetchInstrutor() {
      const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
      const { json } = await request(url, options);
      console.log(json);
      setInstrutorData(json);
      setInstrutorType(json.type);
    }
    fetchInstrutor();
  }, [alunoData]);

  //Checa se a activação forçada estiver ligada
  if (alunoData.force_activate !== '1')
    return (
      <div className="forbidden">
        <h1>
          Por favor,
          <br />
          entre em contato com
          <br />
          seu instrutor.
        </h1>
      </div>
    );

  //se hover da da semana selecionado
  if (currentDay === '')
    return (
      <section className={styles.meuTreino}>
        <Header page="aluno" />
        <div className={styles.alunoTreino}>
          {instrutorType === 'master' && (
            <div className={styles.banner}>
              <Video
                codigo={instrutorData.video_aluno}
                size="240p"
                controls={true}
              />
            </div>
          )}
          <div className={styles.exercicios}>
            {calendario[currentWeek[0]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Domingo',
                    treinos: treinoWeek.domingo,
                    day: currentWeek[0],
                  })
                }
              >
                <p>DOMINGO</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>DOMINGO</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[1]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Segunda-Feira',
                    treinos: treinoWeek.segunda,
                    day: currentWeek[1],
                  })
                }
              >
                <p>SEGUNDA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SEGUNDA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[2]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Terça-Feira',
                    treinos: treinoWeek.terca,
                    day: currentWeek[2],
                  })
                }
              >
                <p>TERÇA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>TERÇA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[3]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Quarta-Feira',
                    treinos: treinoWeek.quarta,
                    day: currentWeek[3],
                  })
                }
              >
                <p>QUARTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>QUARTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[4]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Quinta-Feira',
                    treinos: treinoWeek.quinta,
                    day: currentWeek[4],
                  })
                }
              >
                <p>QUINTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>QUINTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[5]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Sexta-Feira',
                    treinos: treinoWeek.sexta,
                    day: currentWeek[5],
                  })
                }
              >
                <p>SEXTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SEXTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[6]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Sábado',
                    treinos: treinoWeek.sabado,
                    day: currentWeek[6],
                  })
                }
              >
                <p>SÁBADO</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SÁBADO</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}
          </div>
        </div>
      </section>
    );

  /*---------------------------------------------------------------------*/
  //se hover da da semana selecionado
  if (currentDay !== '')
    return (
      <section className={styles.meuTreino}>
        <Header page="aluno" />
        <div className={styles.alunoTreino}>
          <div className={styles.exercicios2}>
            <Weeks
              treino={currentDay}
              setCurrentDay={setCurrentDay}
              today={today}
              alunoData={alunoData}
              setCalendario={setCalendario}
            />
          </div>
        </div>
      </section>
    );
};

export default AlunoTreino;
