import React from 'react';
import styles from './ManageAdv.module.css';
import Header from '../../Header/Header';
import Button from '../../Forms/Button';
import Error from '../../Helper/Error';
import useFetch from '../../Hooks/useFetch';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { MANAGER_PUT } from '../../../api';

const ManageAdv = () => {
  const token = window.localStorage.getItem('token');
  const { loading, request } = useFetch();
  const { userID, userType } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);

  const [videoAluno, setVideoAluno] = React.useState();
  const [videoInstrutor, setVideoInstrutor] = React.useState();

  //---BOTÃO DE ENVIO DE EMAIL

  function handleClick(e) {
    e.preventDefault();

    const body = {
      videoAluno: videoAluno,
      videoInstrutor: videoInstrutor,
    };

    async function fetchInput() {
      const { url, options } = MANAGER_PUT({ token, body });
      const { response } = await request(url, options);
      console.log(response);

      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Vídeos alterados.',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro.',
        });
      }
    }
    fetchInput();
  }

  return (
    <section className={styles.manageAdv}>
      <Header page="addUsuario" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Área do Editor</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.basics}>
          <label>
            Vídeo Aluno (Código Vímeo)
            <input
              label="Vídeo Aluno"
              type="text"
              name="nome"
              value={videoAluno}
              onChange={(e) => setVideoAluno(e.target.value)}
            />
          </label>
          <label>
            Vídeo Instrutor (Código Vímeo)
            <input
              label="Vídeo Instrutor"
              type="text"
              name="nome"
              value={videoInstrutor}
              onChange={(e) => setVideoInstrutor(e.target.value)}
            />
          </label>
        </div>
        <div className={styles.btEnviar}>
          {loading ? (
            <Button disabled>Atualizando...</Button>
          ) : (
            <Button onClick={handleClick}>Atualizar</Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default ManageAdv;
