import React from 'react';
import styles from './EditarCadastroAluno.module.css';
import Input from '../../Forms/Input';
import useForm from '../../Hooks/useForm';
import Button from '../../Forms/Button';
import Error from '../../Helper/Error';
import TextArea from '../../Forms/TextArea';
import useFetch from '../../Hooks/useFetch';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { USER_PUT } from '../../../api';
import Switch from 'react-switch';
import Loading from '../../Helper/Loading';

const EditarCadastroAluno = ({ alunoData, alunoID }) => {
  const token = window.localStorage.getItem('token');
  const { data, loading, error, request } = useFetch();
  const { userType } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const sobrenome = useForm();
  const email = useForm('email');
  const whatsapp = useForm();
  const [loading2, setLoading2] = React.useState(true);
  const [obs, setObs] = React.useState('');
  const [plano, setPlano] = React.useState('');
  const [erro, setErro] = React.useState('');

  const [forceActive, setForceActive] = React.useState({ checked: false });

  const validaFields = () => {
    setErro('');
    if (
      nome.validate() &&
      sobrenome.validate() &&
      email.validate() &&
      whatsapp.validate()
    ) {
      return true;
    }
    return false;
  };

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleClick(e) {
    e.preventDefault();
    setErro('');

    const body = {
      ...alunoData,
      nome: nome.value,
      sobrenome: sobrenome.value,
      email: email.value,
      whatsapp: whatsapp.value,
      observacoes: obs,
      plano: plano,
      force_activate: forceActive.checked,
    };
    console.log(body);

    if (validaFields()) {
      try {
        const { url, options } = USER_PUT({ token, body, alunoID });
        const { response, json } = await request(url, options);
        console.log(json);
        if (response.ok) {
          setNotify({
            type: 'success',
            message: 'Usuário alterado.',
          });
        } else {
          setNotify({
            type: 'error',
            message: 'Usuário já existe.',
          });
        }
      } catch (err) {
        setErro(err.message);
      } finally {
        setErro('');
      }
    } else {
      setErro('Preencha os campos corretamente.');
    }
  }

  function handlePlanos(e) {
    e.preventDefault();
    setPlano(e.target.value);
  }

  const handleChange = (nextChecked) => {
    //console.log(nextChecked);
    setForceActive({ checked: nextChecked });
  };

  React.useEffect(() => {
    if (alunoData) {
      console.log(alunoData);
      nome.initialValue(alunoData.nome);
      sobrenome.initialValue(alunoData.sobrenome);
      email.initialValue(alunoData.email);
      whatsapp.initialValue(alunoData.whatsapp);
      setObs(alunoData.observacoes);
      setPlano(alunoData.plano);
      setForceActive({ checked: Boolean(Number(alunoData.force_activate)) });
      setLoading2(false);
    }
  }, [alunoData]);

  if (loading2) return <Loading type="default" />;
  if (alunoData)
    return (
      <div className={`${styles.editarCadastro} animeLeft`}>
        <div className={styles.basics}>
          <Input label="Nome" type="text" name="nome" {...nome} />
          <Input
            label="Sobrenome"
            type="email"
            name="sobrenome"
            {...sobrenome}
          />
          <Input label="Email" type="email" nome="email" {...email} />
          <Input label="Whatsapp" type="email" nome="email" {...whatsapp} />
          {userType === 'master' && (
            <select
              name="planos"
              id="planos"
              value={plano}
              className={styles.select}
              onChange={handlePlanos}
            >
              <option value="1">Plano1</option>
              <option value="2">Plano2</option>
              <option value="3">Plano3</option>
            </select>
          )}
          <label className={`${styles.label} `}>
            Forçar ativação
            <Switch
              onChange={handleChange}
              checked={forceActive.checked}
              className={styles.reactSwitch}
            />
          </label>
        </div>

        <div className={styles.textarea}>
          <TextArea
            label="Observações sobre o usuário:"
            obs={obs}
            setObs={setObs}
          />
        </div>
        <div className={styles.btEnviar}>
          {loading ? (
            <Button disabled>Atualizando...</Button>
          ) : (
            <Button onClick={handleClick}>Atualizar Usuário</Button>
          )}
          {!validaFields.value && <Error message={erro} />}
        </div>
      </div>
    );
};

export default EditarCadastroAluno;
