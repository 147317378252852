import React from 'react';
import { EXERCICIO_GET, GRUPO_GET, TREINO_GET } from '../../../api';
import Loading from '../../Helper/Loading';
import useFetch from '../../Hooks/useFetch';
import styles from './ManageAreaEditarTreino.module.css';
import TreinoItem from './../AdicionarTreino/TreinoItem';
import { ReactSortable } from 'react-sortablejs';
import { UserContext } from '../../Context/UserContext';
import { ModalContext } from '../../Context/ModalContext';

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  handle: '.move',
};

const ManageAreaEditarTreino = ({
  searchField,
  selected,
  setSelected,
  exID,
  setNomeTreino,
}) => {
  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);

  const { userID } = React.useContext(UserContext);
  //console.log(userID);

  const token = window.localStorage.getItem('token');

  const { data, loading, error, request } = useFetch();

  const initialValue = [
    {
      atributos: [
        { label: 'Séries', value: ' -', id: 1 },
        { label: 'Repetições', value: ' -', id: 2 },
        { label: 'Fazer Por', value: ' -', id: 3 },
        { label: 'Manter Por', value: ' -', id: 4 },
        { label: 'Descanso', value: ' -', id: 5 },
        { label: 'Carga', value: ' -', id: 6 },
        { label: 'Elástico', value: ' -', id: 7 },
      ],
    },
  ];

  const fetchMyAPI = React.useCallback(async () => {
    if (userID) {
      const { url, options } = TREINO_GET({
        exID,
        token,
        userID,
      });
      const { response, json } = await request(url, options);
      //console.log(response);
      // console.log(json);
      const tempArr1 = json[0].grade_exercicios;
      const tempArr2 = tempArr1.replace('"[[{"', '[[{"');
      const tempArr3 = tempArr2.replace('}]}]]",', '}]}]],');
      //console.log(tempArr3);
      //  console.log(JSON.parse(tempArr3));
      setItensExercicio(JSON.parse(tempArr3));
      //setItensExercicio(JSON.parse(json[0].grade_exercicios));
      setNomeTreino(json[0].nome);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [request, userID]);

  React.useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  //fetch quando escolhe um exercicio na busca
  React.useEffect(() => {
    async function fetchSearch() {
      if (searchField) {
        //console.log(searchField);
        if (searchField.post_type === 'exercicios') {
          const exID = searchField.id;

          const { url, options } = EXERCICIO_GET({ exID, token });
          const { json } = await request(url, options);

          //Checa se o valor já exsite antes de adicionar
          const isFound = itensExercicio.some((element) => {
            if (element.id === Number(json[0].id)) {
              return true;
            }
            return false;
          });

          if (isFound) {
            console.log('✅ Exercício já foi adicionado');
          } else {
            //console.log(itensExercicio);
            let tempArr = json.map((item) => {
              return [{ dados: item }, ...initialValue];
            });
            // console.log(tempArr);
            //setItensExercicio(tempArr);
            setItensExercicio((itensExercicio) => [
              ...itensExercicio,
              tempArr[0],
            ]);
            //const teste = [...itensExercicio, tempArr[0]];
            //console.log(itensExercicio);
            //console.log(teste);
          }
          //---SE FOR GRUPO-----
        } else {
          const grupoID = searchField.id;
          // console.log(searchField);
          const { url, options } = GRUPO_GET({ grupoID, userID, token });
          const { response, json } = await request(url, options);

          //Checa se o valor já exsite antes de adicionar
          const isFound = itensExercicio.some((element) => {
            if (element.id === Number(json[0].id)) {
              return true;
            }
            return false;
          });

          if (isFound) {
            console.log('✅ Grupo já foi adicionado');
          } else {
            //console.log(itensExercicio);
            let tempArr = json.map((item) => {
              return [{ dados: item }, ...initialValue];
            });
            console.log(tempArr);
            //setItensExercicio(tempArr);
            setItensExercicio((itensExercicio) => [
              ...itensExercicio,
              tempArr[0],
            ]);
            //const teste = [...itensExercicio, tempArr[0]];
            //console.log(teste);
          }
        }
      }
    }
    fetchSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  if (error) console.log(error);
  if (loading) return <Loading type="default" />;

  if (data)
    return (
      <ul className={styles.manageArea}>
        <ReactSortable
          list={itensExercicio}
          setList={setItensExercicio}
          {...sortableOptions}
        >
          {itensExercicio.map((item, index) => (
            <TreinoItem
              key={item[0].dados.id}
              item={item}
              selected={selected}
              setSelected={setSelected}
              index={index}
            />
          ))}
        </ReactSortable>
      </ul>
    );
  else return <h1 className={styles.noData}>Aguarde. Carregando...</h1>;
};

export default ManageAreaEditarTreino;
