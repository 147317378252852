import React from 'react';
import styles from './BoxGraphic.module.css';

const BoxGraphic = ({ titulo, data }) => {
  return (
    <section className={styles.graphic}>
      <h2 className={styles.titulo}>{titulo}</h2>
      <div className={styles.content}>{data}</div>
    </section>
  );
};

export default BoxGraphic;
