import React from 'react';
import styles from './AddUsuario.module.css';
import Header from '../../Header/Header';
import Input from '../../Forms/Input';
import useForm from '../../Hooks/useForm';
import Button from '../../Forms/Button';
import Error from '../../Helper/Error';
import TextArea from '../../Forms/TextArea';
import useFetch from '../../Hooks/useFetch';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { USER_POST } from '../../../api';

const AddUsuario = () => {
  const token = window.localStorage.getItem('token');
  const { loading, request } = useFetch();
  const { userID, userType } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const sobrenome = useForm();
  const email = useForm('email');
  const whatsapp = useForm('phone');
  const cref = useForm();
  const [obs, setObs] = React.useState('');
  const [plano, setPlano] = React.useState('');
  const [type, setType] = React.useState('aluno2');
  const [erro, setErro] = React.useState('');

  const validType = React.useRef('');

  const validaFields = () => {
    console.log(
      'nome: ' + nome.value,
      'sebrenome: ' + sobrenome.value,
      'email: ' + email.value,
      'whatsapp: ' + whatsapp.value,
      'cref: ' + cref.value,
      'validType: ' + type,
      'type: ' + type,
    );

    setErro('');
    if (validType.current.value === 'aluno1') {
      if (
        nome.validate() &&
        sobrenome.validate() &&
        email.validate() &&
        whatsapp.validate()
      ) {
        //console.log(1);
        return true;
      }
    } else if (validType.current.value === undefined) {
      if (
        nome.validate() &&
        sobrenome.validate() &&
        email.validate() &&
        whatsapp.validate()
      ) {
        // console.log(2);
        return true;
      }
    } else if (validType.current.value === 'instrutor') {
      if (
        nome.validate() &&
        sobrenome.validate() &&
        email.validate() &&
        whatsapp.validate() &&
        cref.validate()
      ) {
        // console.log(2);
        return true;
      }
    }
    console.log(3);
    return false;
  };

  function handleEmpty() {
    nome.onClear();
    sobrenome.onClear();
    email.onClear();
    whatsapp.onClear();
    cref.onClear();
    setObs('');
  }

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleClick(e) {
    e.preventDefault();
    setErro('');

    const body = {
      name: nome.value,
      sobrenome: sobrenome.value,
      email: email.value,
      whatsapp: whatsapp.value,
      cref: cref.value,
      observacoes: obs,
      author: userID,
      role: validType.current.value,
      plano: plano,
    };

    if (validaFields()) {
      try {
        const { url, options } = USER_POST({ token, body });
        const { response } = await request(url, options);
        if (response.ok) {
          setNotify({
            type: 'success',
            message: 'Usuário criado.',
          });
          handleEmpty();
        } else {
          setNotify({
            type: 'error',
            message: 'Usuário já existe.',
          });
        }
      } catch (err) {
        setErro(err.message);
      } finally {
        setErro('');
      }
    } else {
      setErro('Preencha os campos corretamente.');
    }
  }

  function handleType(e) {
    e.preventDefault();
    setType(e.target.value);
    //console.log(type);
    console.log(validType.current.value);
  }

  function handlePlanos(e) {
    e.preventDefault();
    setPlano(e.target.value);
  }

  React.useEffect(() => {
    if (userType === 'master');
    setType('aluno1');
  }, []);

  if (userType === 'master')
    return (
      <section className={styles.addUsuario}>
        <Header page="addUsuario" />
        <div className={styles.leftBar}>
          <h1 className={styles.title}>Adicionar Usuário</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.basics}>
            <Input label="Nome" type="text" name="nome" {...nome} />
            <Input
              label="Sobrenome"
              type="email"
              name="sobrenome"
              {...sobrenome}
            />
            <Input label="Email" type="email" nome="email" {...email} />
            <Input label="Whatsapp" type="email" nome="email" {...whatsapp} />
          </div>
          <label
            htmlFor="userType"
            className={`${styles.label} ${styles.large}`}
          >
            Tipo de Usuário:
            <select
              name="userType"
              id="userType"
              ref={validType}
              onChange={handleType}
              value={type}
              className={styles.select}
            >
              <option value="aluno1">Aluno</option>
              <option value="instrutor">Instrutor</option>
            </select>
          </label>
          {type === 'instrutor' ? (
            <div className={styles.instrutor}>
              <Input label="Cref" type="text" nome="cref" {...cref} />
              <label
                htmlFor="planos"
                className={`${styles.label} ${styles.large}`}
              >
                Escolha o Plano:
                <select
                  name="planos"
                  id="planos"
                  className={styles.select}
                  onChange={handlePlanos}
                >
                  <option value="1">Plano1</option>
                  <option value="2">Plano2</option>
                  <option value="3">Plano3</option>
                </select>
              </label>
            </div>
          ) : (
            <div className={styles.aluno}>
              <select
                name="planos"
                id="planos"
                className={styles.select}
                onChange={handlePlanos}
              >
                <option value="1">Plano1</option>
                <option value="2">Plano2</option>
                <option value="3">Plano3</option>
              </select>
            </div>
          )}
          <div className={styles.textarea}>
            <TextArea
              label="Observações sobre o usuário:"
              obs={obs}
              setObs={setObs}
            />
          </div>
          <div className={styles.btEnviar}>
            {loading ? (
              <Button disabled>Criando...</Button>
            ) : (
              <Button onClick={handleClick}>Criar Usuário</Button>
            )}
            {!validaFields.value && <Error message={erro} />}
          </div>
        </div>
      </section>
    );

  /*-------------------------------------------------------------*/
  if (userType === 'instrutor')
    return (
      <section className={styles.addUsuario}>
        <Header page="addUsuario" />
        <div className={styles.leftBar}>
          <h1 className={styles.title}>Adicionar Usuário</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.basics}>
            <Input label="Nome" type="text" name="nome" {...nome} />
            <Input
              label="Sobrenome"
              type="email"
              name="sobrenome"
              {...sobrenome}
            />
            <Input label="Email" type="email" nome="email" {...email} />
            <Input label="Whatsapp" type="email" nome="email" {...whatsapp} />
          </div>
          <div className={styles.textarea}>
            <TextArea
              label="Observações sobre o usuário:"
              obs={obs}
              setObs={setObs}
            />
          </div>
          <div className={styles.btEnviar}>
            {loading ? (
              <Button disabled>Criando...</Button>
            ) : (
              <Button onClick={handleClick}>Criar Usuário</Button>
            )}
            {!validaFields.value && <Error message={erro} />}
          </div>
        </div>
      </section>
    );
};

export default AddUsuario;
