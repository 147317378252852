import React from 'react';
import styles from './Weeks.module.css';
import { TREINOS_ALUNO_GET, USER_CALENDARIO_PUT } from '../../../api';
import Loading from '../../Helper/Loading';
import { AppContext } from '../../Context/AppContext';
import useFetch from '../../Hooks/useFetch';
import Video from '../ListarExercicios/Video';
import StopWatch from './StopWatch';

const Weeks = ({ treino, setCurrentDay, today, alunoData, setCalendario }) => {
  const token = window.localStorage.getItem('token');
  const ids = treino.treinos.map((item) => item.value);
  //console.log(ids);
  const [grade, setGrade] = React.useState([]);
  const { data, loading, error, request } = useFetch();

  const [modal, setModal] = React.useState(false);
  const [video, setVideo] = React.useState();
  const { setNotify } = React.useContext(AppContext);

  //cronometro
  const [isActive, setIsActive] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(true);
  const [time, setTime] = React.useState(0);

  //pontos
  const [pontos, setPontos] = React.useState(0);

  /*   function isJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  let grade = isJson(dados.grade_exercicios); */

  React.useEffect(() => {
    console.log(alunoData.pontuacao);
    console.log(alunoData.calendario);
    async function fetchEx() {
      const { url, options } = TREINOS_ALUNO_GET({ token, ids });
      const { response, json } = await request(url, options);
      //console.log(json);

      if (json.length > 0) {
        let datinha = [];
        for (let i = 0; i < json.length; i++) {
          const getArr1 = json[i].grade_exercicios;
          const getArr2 = getArr1.replace('"[[{"', '[[{"');
          const getArr3 = getArr2.replace('}]}]]",', '}]}]],');
          datinha.push(JSON.parse(getArr3));
        }
        console.log(datinha);
        // const tempArr = getArr3.map((item) => JSON.parse(item.grade_exercicios));
        //const tempArr = JSON.parse(getArr3);
        // const tempArr2 = [];
        // for (let i = 0; i < tempArr.length; i++) {
        //    tempArr2.push(...tempArr[i]);
        //  }
        //  console.log(tempArr2);
        setGrade(datinha);

        let points = [];
        datinha.map((item) => {
          item.map((i) => {
            if (i[0].dados.post_type === 'exercicios') {
              // console.log(i[1].atributos);
              points.push(
                i[1].atributos[0].value * eval(i[1].atributos[1].value),
              );
            } else {
              i[0].dados.grade_exercicios.map((i2) =>
                points.push(
                  i2[1].atributos[0].value * eval(i2[1].atributos[1].value),
                ),
              );
            }
          });
        });

        const reducer = (accumulator, curr) => accumulator + curr;
        setPontos(points.reduce(reducer));

        //setPontos(points);
      }
    }
    fetchEx();
  }, []);

  async function handleClick(item) {
    //console.log(ids);
    setModal(true);
    //console.log(grade[index][1].atributos);
    // console.log(item);
    setVideo(item);
  }

  async function handleFinish() {
    // console.log(time);
    //console.log(pontos);
    // console.log(today);
    // console.log(treino.day);

    const newDate = {
      ...alunoData.calendario,
      [today]: { pontos: pontos, time: time },
    };
    // console.log(body);
    setCalendario(newDate);

    const body = {
      calendario: newDate,
      pontuacao: Number(alunoData.pontuacao) + Number(pontos),
    };

    const alunoID = alunoData.id;
    const { url, options } = USER_CALENDARIO_PUT({ token, body, alunoID });
    const { response } = await request(url, options);
    //console.log(response);
    //console.log(json);
    if (response.ok) {
      setNotify({
        type: 'success',
        message: 'Treino do dia finalizado',
      });
      setCurrentDay('');
    } else {
      setNotify({
        type: 'error',
        message: error,
      });
    }
  }

  if (loading) return <Loading type="default" />;
  if (data && data.length > 0)
    return (
      <div className={styles.weeks}>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.atributos}>
                <h1>{video[0].dados.nome}</h1>
                <h2>Instruções:</h2>
                {video[1].atributos.map((item, index) => (
                  <p key={index}>
                    <strong>{item.label}: </strong>
                    {item.value}
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.close} onClick={() => setModal(false)}></div>
          </div>
        )}
        <div className={styles.title}>
          <h1>{treino.dia}</h1>
          <span
            className={`${styles.icon} ${styles.back}`}
            onClick={() => setCurrentDay('')}
          ></span>
        </div>
        {today === treino.day ? (
          <StopWatch
            isActive={isActive}
            setIsActive={setIsActive}
            isPaused={isPaused}
            setIsPaused={setIsPaused}
            time={time}
            setTime={setTime}
          />
        ) : (
          <div className={styles.doneNull}>
            Ops, este treino só pode ser realizado no dia correto.
          </div>
        )}
        <div className={styles.videosContent}>
          {grade.map((item) =>
            item.map((i) => (
              <div key={i[0].dados.id}>
                {i[0].dados.post_type === 'exercicios' ? (
                  <div className={styles.videoItem}>
                    <div className={styles.videoPlayer}>
                      <Video
                        codigo={i[0].dados.codigo_video}
                        size="720p"
                        controls={true}
                      />
                    </div>
                    <div className={styles.videoDesc}>
                      <p>{i[0].dados.nome}</p>
                      <div
                        className={styles.icon + ' ' + styles.openAtributos}
                        onClick={() => handleClick(i)}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.grupo}>
                    <h3>{i[0].dados.nome}</h3>
                    <p>Execute na sequencia por {i[0].dados.repeticoes}x</p>
                    {i[0].dados.grade_exercicios.map((i2) => (
                      <div key={i2[0].dados.id} className={styles.videoItem}>
                        <div className={styles.videoPlayer}>
                          <Video
                            codigo={i2[0].dados.codigo_video}
                            size="720p"
                            controls={true}
                          />
                        </div>
                        <div className={styles.videoDesc}>
                          <p>{i2[0].dados.nome}</p>
                          <div
                            className={styles.icon + ' ' + styles.openAtributos}
                            onClick={() => handleClick(i2)}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )),
          )}
        </div>
        {/* 1.800.000 -> 30min*/}
        {time > 4000 ? (
          <div className={styles.done} onClick={handleFinish}>
            FINALIZAR
          </div>
        ) : (
          <div className={styles.doneNull}>
            O TREINO SÓ PODE SER FINALIZADO APÓS 30MIN.
          </div>
        )}
      </div>
    );
  else {
    return (
      <div className={styles.weeks}>
        <div className={styles.title}>
          <h1>{treino.dia}</h1>
          <span
            className={`${styles.icon} ${styles.back}`}
            onClick={() => setCurrentDay('')}
          ></span>
        </div>
        <div className={styles.doneNull}>
          Nenhum treino programado para o dia de hoje.
        </div>
      </div>
    );
  }
};

export default Weeks;
