import React from 'react';
import styles from './ListagemAreaUsuarios.module.css';
import { USERS_GET, USER_DELETE } from '../../../api';
import Loading from '../../Helper/Loading';
import useFetch from '../../Hooks/useFetch';
import { UserContext } from '../../Context/UserContext';
import { AppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';

const ListagemAreaUsuarios = ({ filtro }) => {
  const { userType } = React.useContext(UserContext);
  const token = window.localStorage.getItem('token');
  const { data, loading, error, request } = useFetch();
  const [itensUsuario, setItensUsuarios] = React.useState([]);
  const { setNotify } = React.useContext(AppContext);
  const navigate = useNavigate();

  const page = 1;
  const nItens = -1;
  const q = filtro;

  const fetchMyAPI = React.useCallback(async () => {
    const { url, options } = USERS_GET({
      q,
      page,
      nItens,
      token,
    });
    const { json } = await request(url, options);
    setItensUsuarios(await json);
    // console.log(json);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, q]);

  React.useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  //função DELETE user--------------
  async function deleteUser(exID) {
    //console.log(itensUsuario);
    if (window.confirm('Confirma a exclusão deste usuário?')) {
      const { url, options } = USER_DELETE({
        exID,
        token,
      });
      const { response } = await request(url, options);
      //console.log(response);
      //console.log(json);

      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'O usuário foi excluído.',
        });
        setItensUsuarios((current) =>
          current.filter((itensUsuario) => {
            // 👇️ remove object that has id equal to 2
            return itensUsuario.id !== exID;
          }),
        );
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro na exclusão.',
        });
      }
    }
  }

  if (error) console.log(error);
  if (loading) return <Loading type="default" />;
  if (data)
    return (
      <ul className={styles.listagemAreaUsuarios}>
        {itensUsuario &&
          itensUsuario.map((item) => (
            <li key={item.id} className={styles.itemUser}>
              <img src={item.photo} width="100"></img>
              <div className={styles.userDados}>
                <p>
                  <strong>
                    {item.nome} {item.sobrenome}
                  </strong>
                </p>
                {userType === 'master' && <p>Plano: {item.plano}</p>}
                <p>Status: {item.force_activate ? 'Ativo' : 'Inativo'}</p>
              </div>

              <div className={styles.bts}>
                <span
                  className={`${styles.icon} ${styles.emailUser}`}
                  onClick={() => {
                    window.open('mailto:' + item.email, '_blank');
                  }}
                ></span>
                <span
                  className={`${styles.icon} ${styles.whatsUser}`}
                  onClick={() => {
                    window.open(
                      'https://api.whatsapp.com/send?phone=' + item.whatsapp,
                      '_blank',
                    );
                  }}
                ></span>
                <span
                  className={`${styles.icon} ${styles.editUser}`}
                  onClick={() => {
                    if (item.type === 'instrutor')
                      navigate(
                        `/instrutor/editar-cadastro-instrutor/${item.id}`,
                      );
                    else navigate(`/aluno/editar-treino-aluno/${item.id}`);
                  }}
                ></span>
                <span
                  className={`${styles.icon} ${styles.removeUser}`}
                  onClick={() => {
                    deleteUser(item.id);
                  }}
                ></span>
              </div>
            </li>
          ))}
      </ul>
    );
  else return <h1 className={styles.noData}>Nenhum usuário encontrado!</h1>;
};

export default ListagemAreaUsuarios;
