import React from 'react';
import Footer from '../../Footer';
import Input from '../../Forms/Input';
import Header from '../../Header/Header';
import styles from './AddGrupo.module.css';
import SpecifyBar from '../AdicionarTreino/SpecifyBar';
import SelectExercicios from '../AdicionarTreino/SelectExercicios';
import ManageAreaTreino from '../AdicionarTreino/ManageAreaTreino';
import Button from '../../Forms/Button';
import { GRUPO_POST } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import useForm from '../../Hooks/useForm';
import { UserContext } from '../../Context/UserContext';
import Error from '../../Helper/Error';
import { ModalContext } from '../../Context/ModalContext';
import { AppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';

const AddGrupo = () => {
  const token = window.localStorage.getItem('token');
  const { userID } = React.useContext(UserContext);
  const { loading, error, request } = useFetch();
  const [validate, setValidate] = React.useState(true);
  const nome = useForm();
  //field de busca
  const [searchField, setSearchField] = React.useState(null);
  //exercicios selecionados
  const [selected, setSelected] = React.useState([]);

  const [modal, setModal] = React.useState(false);
  const navigate = useNavigate();

  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);

  //setAllParams((allParams) => [...allParams, initialValue]);

  async function grupoPost() {
    setValidate(true);
    if (nome.value !== '' && itensExercicio.length > 0) {
      const body = {
        nome: nome.value,
        grade_exercicios: itensExercicio,
        author: userID,
        repeticoes: 1,
      };

      const { url, options } = GRUPO_POST({ body, token });
      const { response } = await request(url, options);
      //console.log(response)
      if (response.ok) {
        setModal(true);
      }
    } else {
      setValidate(false);
    }
  }

  function handleReload() {
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
    document.location.reload(true);
  }

  function handleRedirect() {
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
    navigate('/adicionar-treino');
  }

  return (
    <section className={styles.addGrupo}>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h1>GRUPO SALVO COM SUCESSO!</h1>
            <span>
              <Button onClick={handleReload}>ADICIONAR OUTRO</Button>
            </span>
            <span>
              <Button onClick={handleRedirect}>CRIAR TREINO</Button>
            </span>
          </div>
        </div>
      )}
      <Header page="addGrupo" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Criar Grupo de Exercícios</h1>
        <Input
          label="Nome do Grupo"
          type="text"
          name="nome"
          color="#fff"
          {...nome}
        />
        <SpecifyBar selected={selected} setSelected={setSelected} />
        <SelectExercicios type="exercicios" setSearchField={setSearchField} />
        <div className={styles.btSalvar}>
          {loading ? (
            <Button disabled>Salvando...</Button>
          ) : (
            <Button onClick={grupoPost}>Salvar Grupo</Button>
          )}
          <Error message={error} />
          {!validate && <Error message={'Insira o nome e os exercícios'} />}
        </div>
      </div>
      <div className={styles.content}>
        <ManageAreaTreino
          searchField={searchField}
          itensExercicio={itensExercicio}
          setItensExercicio={setItensExercicio}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <Footer />
    </section>
  );
};

export default AddGrupo;
