import React from 'react';
import BoxFinanceiro from './BoxFinanceiro';
import BoxGraphic from './BoxGraphic';
import styles from './Home.module.css';
import Header from '../../Header/Header';
import Footer from '../../Footer';
import UserContext from '../../Context/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';
import Video from '../ListarExercicios/Video';
import { INSTRUTOR_CREF_GET } from '../../../api';
import useFetch from '../../Hooks/useFetch';

const Home = () => {
  const { userAuthor, userType, userForceActivate, login } =
    React.useContext(UserContext);
  //console.log(userType);
  //const navigate = useNavigate();

  const { request } = useFetch();
  const token = window.localStorage.getItem('token');

  const [authorData, setAuthorData] = React.useState();

  React.useEffect(() => {
    const theID = userAuthor;
    async function fetchInstrutor() {
      const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
      const { json } = await request(url, options);
      console.log(json);
      setAuthorData(json);
    }
    fetchInstrutor();
  }, []);

  //Checa se a activação forçada estiver ligada
  if (userForceActivate === '0')
    return (
      <div className="forbidden">
        <h1>
          Por favor,
          <br />
          entre em contato com
          <br />
          seu instrutor.
        </h1>
      </div>
    );

  if (userType === 'master')
    return (
      <section className={styles.home}>
        <Header />
        <div className={styles.banner}></div>
        <div className={styles.boxes}>
          <BoxFinanceiro
            titulo="Faturamento Deste Mês"
            valor="R$ 12.000,00"
            icon="money"
          />
          <BoxFinanceiro
            titulo="Último Mês"
            valor="R$ 10.000,00"
            icon="money"
          />
          <BoxFinanceiro titulo="Este Ano" valor="R$ 60.000,00" icon="money" />
          <BoxFinanceiro titulo="Alunos Ativos" valor="600" icon="users" />
          <BoxFinanceiro
            titulo="Instrutores Ativos"
            valor="42"
            icon="instrutor"
          />
          <BoxFinanceiro titulo="Total de Usuários" valor="700" icon="group" />
        </div>
        <div className={styles.graphics}>
          <BoxGraphic titulo="Latência de Alunos" data="Gráfico" />
          <BoxGraphic titulo="Volume Financeiro" data="Gráfico" />
        </div>
        <Footer />
      </section>
    );

  /*--------------------------------------------------------------------------*/
  if (userType === 'instrutor')
    return (
      <section className={styles.home}>
        <Header />
        <div className={styles.banner_video}>
          {authorData && (
            <Video
              codigo={authorData.video_aluno}
              size="240p"
              controls={true}
            />
          )}
        </div>

        <div className={styles.boxes2}>
          <BoxFinanceiro titulo="Alunos Ativos" valor="15" icon="users" />
          <BoxFinanceiro
            titulo="Exercícios cadastrados"
            valor="400"
            icon="group"
          />
        </div>

        <Footer />
      </section>
    );

  /*----------------------------------------------------------------------------*/
  if (userType === 'aluno1' || userType === 'aluno2')
    //window.location.href = '/meu-treino';
    return <Navigate to="/meu-treino" />;

  /*----------------------------------------------------------------------------*/
  if (!login)
    //window.location.href = '/meu-treino';
    return <Navigate to="/login" />;
};

export default Home;
