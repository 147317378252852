import React from 'react';

export const AppContext = React.createContext();

export const AppStorage = ({ children }) => {
  const [notify, setNotify] = React.useState({
    type: '',
    message: '',
  });
  return (
    <AppContext.Provider value={{ notify, setNotify }}>
      {children}
    </AppContext.Provider>
  );
};
